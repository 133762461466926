'use strict';
import { createRoot } from 'react-dom/client';
import { useState, useEffect } from 'react';

const Countdown = ({ deadline }) => {
  const [dateState, setDateState] = useState(
    {
      day: 0,
      hour: 0,
      min: 0,
      sec: 0,
    }
  );

  const leadingZero = (number) => {
    return number < 10 ? `0${number}` : number
  }

  const getCountdownTime = (deadline) => {
    const currentDate = new Date();
    const diff = deadline - currentDate;

    let day = Math.floor(diff / 86400000); // time diff in days (modulated to 30)
    let hour = Math.floor((diff / 3600000) % 24); // time diff's hours (modulated to 24)
    let min = Math.floor((diff / 60000) % 60); // time diff's minutes (modulated to 60)
    let sec = Math.floor((diff / 1000) % 60); // time diff's seconds (modulated to 60)

    setDateState({
      day: day,
      hour: hour,
      min: min,
      sec: sec,
      targetDate: dateState.targetDate
    });
  };
  

  useEffect(() => {
    setInterval(() => getCountdownTime(deadline), 1000);

    return () => getCountdownTime(deadline);
  }, [deadline]);

  return (
    <div className="row mx-0 justify-content-center">
      <div className="col col-md-6 col-lg-5 col-xl-4 position-relative pt-3" id="countdown">
        <div className="row gx-0 timer-container">
          <p className="col col-md-3 fs-1 fw-bolder lh-1">
            { dateState.day }
            <br/>
            <span className="fs-4 fw-normal">
              Dias
            </span>
          </p>
          <p className="col col-md-3 fs-1 fw-bolder lh-1">
            { leadingZero(dateState.hour) }
            <br/>
            <span className="fs-4 fw-normal">
              HH
            </span>
          </p>
          <p className="col col-md-3 fs-1 fw-bolder lh-1">
            { leadingZero(dateState.min) }
            <br/>
            <span className="fs-4 fw-normal">
              MM
            </span>
          </p>
          <p className="col col-md-3 fs-1 fw-bolder lh-1">
            { leadingZero(dateState.sec) }
            <br/>
            <span className="fs-4 fw-normal">
              SS
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
