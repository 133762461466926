'use strict';
import { createRoot } from 'react-dom/client';
import { useState } from 'react';
import Countdown from './countdown';

const CountdownContainer = () => {
  return (
    <article className="container-fluid p-0 text-center">
      <Countdown deadline={new Date('September 15, 2023 18:00:00 GMT+02:00')} />
    </article>
  );
};

const root = createRoot(document.getElementById('countdown-container'));
root.render(<CountdownContainer />);
